import WrinklesTemplate from '@/modules/questionnaire/components/steps/questionnaire/skin-appearance/wrinkles/WrinklesTemplate';

import { FACE_WRINKLES } from '@/modules/questionnaire/api/constants';

export default {
  title: 'Steps/SkinAppearance/Wrinkles/WrinklesTemplate',
  component: WrinklesTemplate
};

const createStory = props => () => ({
  components: { WrinklesTemplate },
  storyProps: props,
  wrapperStyles: {
    display: 'flex',
    justifyContent: 'center'
  },
  template:
    '<div :style="$options.wrapperStyles"><wrinkles-template v-bind="$options.storyProps" /></div>'
});

export const base = createStory({
  wrinkles: FACE_WRINKLES.FACE_DEEP_LINES
});

export const unselected = createStory({
  wrinkles: ''
});
